import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168')
];

export const server_loads = [0,2,3,8,12];

export const dictionary = {
		"/": [23],
		"/(protected)/admin/(pages)/bank-accounts": [27,[2,3,4]],
		"/(protected)/admin/(pages)/businesses": [28,[2,3,4]],
		"/(protected)/admin/(forms)/businesses/new": [24,[2,3]],
		"/(protected)/admin/(pages)/businesses/[id]": [29,[2,3,4]],
		"/(protected)/admin/(pages)/enterprises": [30,[2,3,4]],
		"/(protected)/admin/(forms)/enterprises/new": [25,[2,3]],
		"/(protected)/admin/(pages)/enterprises/[id]": [31,[2,3,4]],
		"/(protected)/admin/(pages)/printers": [32,[2,3,4]],
		"/(protected)/admin/(pages)/readers": [33,[2,3,4]],
		"/(protected)/admin/(pages)/users": [34,[2,3,4]],
		"/(protected)/admin/(forms)/users/new": [26,[2,3]],
		"/(protected)/admin/(pages)/users/[id]": [35,[2,3,4]],
		"/api/token/[token]": [~168],
		"/(unprotected)/challenge/[id]": [~158],
		"/(unprotected)/changelog": [159],
		"/(protected)/customers/(pages)/transactions/checks": [36,[2,5]],
		"/(protected)/customers/(pages)/transactions/checks/[id]": [37,[2,5]],
		"/(protected)/customers/(pages)/transactions/payments": [38,[2,5]],
		"/(protected)/dashboard": [39,[2]],
		"/(unprotected)/error": [160],
		"/(protected)/finance/(pages)": [56,[2,6,7]],
		"/(protected)/finance/(pages)/accounts": [57,[2,6,7]],
		"/(protected)/finance/(forms)/add-funds": [40,[2,6]],
		"/(protected)/finance/(forms)/add-funds/debit": [41,[2,6]],
		"/(protected)/finance/(forms)/add-funds/transfer": [42,[2,6]],
		"/(protected)/finance/(forms)/agreements": [43,[2,6]],
		"/(protected)/finance/(pages)/all-cards": [58,[2,6,7]],
		"/(protected)/finance/(forms)/all-cards/new": [44,[2,6]],
		"/(protected)/finance/(pages)/bills": [59,[2,6,7]],
		"/(protected)/finance/(forms)/create-demand": [45,[2,6]],
		"/(protected)/finance/(forms)/create-penalty": [46,[2,6]],
		"/(protected)/finance/(pages)/expenses": [60,[2,6,7]],
		"/(protected)/finance/(forms)/link-account": [47,[2,6]],
		"/(protected)/finance/(forms)/make-payment": [48,[2,6]],
		"/(protected)/finance/(pages)/my-cards": [61,[2,6,7]],
		"/(protected)/finance/(forms)/onboard": [49,[2,6]],
		"/(protected)/finance/(pages)/partners": [62,[2,6,7]],
		"/(protected)/finance/(forms)/pay-bill": [50,[2,6]],
		"/(protected)/finance/(forms)/pay-demand/[id]": [51,[2,6]],
		"/(protected)/finance/(pages)/payment-requests": [63,[2,6,7]],
		"/(protected)/finance/(forms)/pending": [52,[2,6]],
		"/(protected)/finance/(pages)/recipients": [64,[2,6,7]],
		"/(protected)/finance/(forms)/recipients/new": [53,[2,6]],
		"/(protected)/finance/(forms)/rfis": [54,[2,6]],
		"/(protected)/finance/(pages)/spend-limits": [65,[2,6,7]],
		"/(protected)/finance/(forms)/spend-limits/new": [55,[2,6]],
		"/(protected)/food-and-beverages/(pages)/availability": [66,[2,8]],
		"/(protected)/food-and-beverages/(pages)/discounts": [67,[2,8]],
		"/(protected)/food-and-beverages/(pages)/menus": [68,[2,8]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/active-instances": [69,[2,8,9]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/edit": [70,[2,8,9]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/publish": [71,[2,8,9]],
		"/(protected)/food-and-beverages/(pages)/modifier-groups": [72,[2,8]],
		"/(protected)/food-and-beverages/(pages)/modifier-options": [73,[2,8]],
		"/(protected)/food-and-beverages/(pages)/products": [74,[2,8]],
		"/(unprotected)/forgot-password": [161],
		"/(protected)/help/[id]": [~75,[2]],
		"/(protected)/inventory/(pages)/allergens": [76,[2,10]],
		"/(protected)/inventory/(pages)/counts/past": [78,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/past/[id]": [79,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/past/[id]/variance/[itemId]": [80,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/recurring": [81,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/upcoming": [82,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/[id]": [77,[2,10,11]],
		"/(protected)/inventory/(pages)/groups": [83,[2,10]],
		"/(protected)/inventory/(pages)/groups/[id]": [84,[2,10]],
		"/(protected)/inventory/(pages)/invoices/new": [86,[2,10]],
		"/(protected)/inventory/(pages)/invoices/[id]": [85,[2,10]],
		"/(protected)/inventory/(pages)/items": [87,[2,10]],
		"/(protected)/inventory/(pages)/items/[id]": [88,[2,10]],
		"/(protected)/inventory/(pages)/locations": [89,[2,10]],
		"/(protected)/inventory/(pages)/measurements/conversions": [90,[2,10]],
		"/(protected)/inventory/(pages)/measurements/table": [91,[2,10]],
		"/(protected)/inventory/(pages)/recipes/prep-items": [92,[2,10]],
		"/(protected)/inventory/(pages)/recipes/prep-items/[id]": [93,[2,10]],
		"/(protected)/inventory/(pages)/vendors": [94,[2,10]],
		"/(protected)/inventory/(pages)/vendors/[id]": [95,[2,10]],
		"/(unprotected)/join/[id]": [162],
		"/(unprotected)/login": [163],
		"/(protected)/overview": [96,[2]],
		"/(protected)/people/(new)/dailies": [107,[2,14]],
		"/(protected)/people/(new)/payroll": [108,[2,14]],
		"/(protected)/people/(new)/payroll/[startDate]/[endDate]": [109,[2,14]],
		"/(protected)/people/(new)/payroll/[startDate]/[endDate]/[staffId]": [110,[2,14]],
		"/(protected)/people/(legacy)/scheduling": [~97,[2]],
		"/(protected)/people/(legacy)/scheduling/requests/availabilities/[status]": [101,[2,13]],
		"/(protected)/people/(legacy)/scheduling/requests/cover-requests/[status]": [102,[2,13]],
		"/(protected)/people/(legacy)/scheduling/requests/drop-requests/[status]": [103,[2,13]],
		"/(protected)/people/(legacy)/scheduling/requests/time-off-requests/[status]": [104,[2,13]],
		"/(protected)/people/(legacy)/scheduling/requests/trade-requests/[status]": [105,[2,13]],
		"/(protected)/people/(legacy)/scheduling/settings": [106,[2]],
		"/(protected)/people/(legacy)/scheduling/[startDate]/[endDate]/day": [98,[2,12]],
		"/(protected)/people/(legacy)/scheduling/[startDate]/[endDate]/shift": [99,[2,12]],
		"/(protected)/people/(legacy)/scheduling/[startDate]/[endDate]/staff": [100,[2,12]],
		"/(protected)/people/(new)/team": [111,[2,14]],
		"/(protected)/people/(new)/team/archived-employees": [112,[2,14]],
		"/(protected)/people/(new)/team/departments": [113,[2,14]],
		"/(protected)/people/(new)/team/pending": [114,[2,14]],
		"/(protected)/people/(new)/team/roles": [115,[2,14]],
		"/(protected)/people/(new)/team/staff/[status]/[id]": [116,[2,14]],
		"/(protected)/profile/address": [117,[2,16]],
		"/(protected)/profile/general": [118,[2,16]],
		"/(protected)/profile/login-history": [119,[2,16]],
		"/(protected)/profile/security": [120,[2,16]],
		"/(protected)/reporting": [121,[2,17]],
		"/(protected)/reporting/bank-deposits": [122,[2,17]],
		"/(protected)/reporting/daily-summary": [~123,[2,17]],
		"/(protected)/reporting/daily-summary/[start]/[comparison]": [~124,[2,17]],
		"/(protected)/reporting/eod": [125,[2,17]],
		"/(protected)/reporting/gift-cards": [126,[2,17]],
		"/(protected)/reporting/gift-cards/balances": [127,[2,17]],
		"/(protected)/reporting/gift-cards/history": [128,[2,17]],
		"/(protected)/reporting/gift-cards/transactions": [129,[2,17]],
		"/(protected)/reporting/reports": [130,[2,17]],
		"/(protected)/reservations/general": [131,[2,18]],
		"/(protected)/reservations/notifications": [132,[2,18]],
		"/(protected)/reservations/online": [133,[2,18]],
		"/(protected)/reservations/reporting": [134,[2,18]],
		"/(protected)/reservations/service-periods": [135,[2,18]],
		"/(unprotected)/reset-password/[token]": [164],
		"/(protected)/scheduling/(pages)/logs/[date]": [137,[2,19]],
		"/(protected)/scheduling/(forms)/schedule/[date]": [136,[2]],
		"/(protected)/scheduling/(pages)/schedule/[startDate]/[endDate]": [138,[2,19]],
		"/(unprotected)/select": [~165,[22]],
		"/(unprotected)/select/business": [~166,[22]],
		"/(protected)/settings/(pages)": [139,[2,20]],
		"/(protected)/settings/(pages)/account": [140,[2,20]],
		"/(protected)/settings/(pages)/general-service": [142,[2,20]],
		"/(protected)/settings/(pages)/general": [141,[2,20]],
		"/(protected)/settings/(pages)/online-ordering": [143,[2,20]],
		"/(protected)/settings/(pages)/pos": [144,[2,20]],
		"/(protected)/settings/(pages)/printing": [145,[2,20]],
		"/(protected)/store-setup/(pages)/comp-reasons": [146,[2,21]],
		"/(protected)/store-setup/(pages)/floor-plans": [147,[2,21]],
		"/(protected)/store-setup/(pages)/kds": [148,[2,21]],
		"/(protected)/store-setup/(pages)/order-kitchen-display": [~149,[2,21]],
		"/(protected)/store-setup/(pages)/printers": [150,[2,21]],
		"/(protected)/store-setup/(pages)/service-areas": [151,[2,21]],
		"/(protected)/store-setup/(pages)/service-fees": [152,[2,21]],
		"/(protected)/store-setup/(pages)/table-management": [153,[2,21]],
		"/(protected)/store-setup/(pages)/taxes": [154,[2,21]],
		"/(protected)/store-setup/(pages)/taxes/[id]": [155,[2,21]],
		"/(protected)/store-setup/(pages)/tip-outs": [156,[2,21]],
		"/(protected)/store-setup/(pages)/void-reasons": [157,[2,21]],
		"/(unprotected)/stripe-reader/register": [167]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';